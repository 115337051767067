.delivery-route-printout {
    .header {
        background-color: #ffffff;
        height: 5rem;
        border-bottom: 2px solid #404040;

        h4 {
            margin: 0;
            font-weight: 600 !important;
            font-size: 1.9rem;
        }

        p {
            margin: 0 4px;
            font-size: 1.5rem;
            font-weight: 400;
        }
    }

    .total-items {
        margin-top: 15px;

        .table-row {
            margin: 0;
            padding: 0;

            h6 {
                margin: 5px 0;
                font-size: 1.1rem;
                font-weight: 600;
            }

            p {
                margin: 5px 0;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }

    .route-display {
        margin: 20px 0;

        h4 {
            margin: 0;
            font-size: 1.3rem;
            font-weight: 600 !important;
        }

        .left-section {
            border-right: 1.5px solid #111111;

            h5 {
                margin: 0;
                font-size: 1.2rem;
                font-weight: 400 !important;
            }

            h6 {
                margin: 0;
                font-size: .9rem;
                font-weight: 400 !important;
            }

            p {
                margin: 0;
                font-size: .8rem;
                font-weight: 200;
            }
        }

        .delivery-route-card {
            border-radius: 15px;
            outline: 1.5px solid #111111;
            page-break-inside: avoid;

            .location {
                padding: 0;
                margin-right: 6px;

                height: 40px;
                width: 40px;
                color: $lightblue;
            }

            .customer-contact {
                margin: 10px 0;

                .avatar {
                    margin-left: 6px;
                    margin-right: 10px;
                    height: 30px;
                    width: 30px;
                    padding: 2px;

                    border-radius: 50%;
                    outline: 1px solid #615b5b;
                    color: white;
                    background-color: #615b5b;
                    ;
                }
            }

            .right-section {
                h6 {
                    padding: 0;
                    margin-bottom: 10px;
                    margin-left: 20px;
                    font-size: .9rem;
                    font-weight: 600;
                }

                .reservation-item-container {
                    column-count: 2;
                    column-fill: auto;

                    .reservation-item {
                        height: 33%;
                        margin-left: 5px;
                        padding: 10px;

                        h6 {
                            margin: 0;
                            font-size: .8rem;
                            font-weight: 600;
                        }

                        p {
                            margin: 0;
                            font-size: .6rem;
                            font-weight: 200;
                        }
                    }
                }

                .reservation-amount {
                    height: 25px;
                    width: 25px;
                    margin: 5px;
                    border-radius: 50%;
                    outline: 1.5px solid #111111;
                }
            }
        }
    }
}

.delivery-routes {
    .go-to-pack {
        width: 85%;
        border-radius: 25px;
        margin-bottom: 8px;
        padding: 3px 0;

        background-color: #00b4d2;
        color: white;

        font-size: .8rem;
        font-weight: 600 !important;
    }

    Button:hover {
        background-color: #007489;
        color: white;
    }
}