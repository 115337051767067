.wush-card {
  border-radius: 10px !important;
  border: 2px solid darkgrey;
  box-shadow: rgba(99, 99, 99, 1) 0px 1px 5px 0px !important;

  .MuiCardHeader-root {
    padding: 5px 12px;

    &.green {
      background-color: #99c24e;
    }

    &.pickup {
      background-color: #f76262;
    }

    .card-header-text {
      padding: 0;
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;

      .expand-less {
        color: white;
      }
    }

  }

  .card-header-id {
    font-size: 1rem;
    font-weight: 600;
  }

  .MuiCardHeader-action {
    margin: 0;
    color: white;
  }

  .MuiCardHeader-title {
    padding-left: 10px;
  }


  .MuiCardContent-root {
    padding-top: 0;
    padding-bottom: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .MuiCardActions-root {
    justify-content: right;
  }

  .card-content-row {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;

    .bg_outline {
      border-radius: 50%;
      outline-style: solid;
      outline-width: 1.5px;
      outline-color: black;
      padding: 1.5px;

      &.dark {
        color: white;
        background-color: rgb(100, 99, 99);
        outline-color: rgb(100, 99, 99);
      }
    }

    .dates {
      width: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      font-size: .8rem;
      font-weight: bold;
      // overflow: auto;
    }

    &.blue {
      background-color: #ACCEF7;
    }

    &.green {
      background-color: #99c24e;
    }

    &.outline {
      border: 2px solid darkgrey;
      align-items: center;
      margin-bottom: 0;
    }
  }

  .MuiCardFooter {
    padding-bottom: 0;
    padding-top: 0;
    margin-top: -10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .service-ticket {
      border-radius: 25px;
      border: 2px solid darkgrey;
      padding: 2px 10px;
      font-size: .8rem;
    }

    .expand-styles {
      border-radius: 50%;
      outline-style: solid;
      outline-width: 2px;
      outline-color: darkgrey;
      padding: 2px;
    }
  }

  // Delivery Route Card Styling
  .route-card-style {
    margin: 0;
    padding: 0;
    padding-left: 10px;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    &.header {
      margin: 6px 0;

      background-color: #99c24e;
      color: white;

      border-radius: 10px;

      h6 {
        font-size: 1.1rem;
        font-weight: 600;
      }
    }

    &.route-body {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      background-color: #e5d4bc;
      border-radius: 10px;

      .start-end-addys {
        width: 90%;
      }

      .addy-info {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: 100%;
        padding: 0;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        p {
          font-size: .8rem;
          font-weight: 600;
        }


      }

      .addy-collapse {
        width: 100%;
      }

      .addy-details {
        background-color: white;
        border-radius: 7px;
        width: 100%;
        padding-left: 5px;
      }

      .align {
        align-self: flex-end;
      }

      .green {
        color: #99c24e;
      }

      .gray {
        color: darkgray;
      }
    }

    &.items {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 1px 5px;
      margin: 5px 0;

      border-radius: 10px;
      outline-style: solid;
      outline-width: 1.5px;
      outline-color: black;

      p {
        font-size: .9rem;
        font-weight: 600;
      }

      .list-icon {
        margin: 0 7px;
        border-radius: 50%;
        outline-style: solid;
        outline-width: 1px;
        padding: 1px;

        color: white;
        background-color: #646463;
        outline-color: #646463;
      }


    }

    &.whitespace {
      height: 70px;
    }

    //footer styling
    &.footer {
      margin: 0;
      justify-content: space-between;
      align-items: baseline;
      padding-left: 0;

      color: #90908e;

      button {
        padding: 0;
      }

      .expand {
        transform: rotate(-45deg);

        border-radius: 50%;
        outline-style: solid;
        outline-width: 1.5px;
        outline-color: #90908e;
      }
      
    }
  }
}

.dialog-reservation-overlay {
  padding: 25px;
  border: 2px solid #000;
  border-radius: 10px;
}

.delivery-route-snippet-card {
  margin: 0 20px;
  padding: 3px 0;
  box-shadow: 0px 0px 5px 2px #646463;
  border-radius: 8px;

  .route-box {
    display: flex;
    background-color: #99c24e;
    border-radius: 15px;
    padding: 2px 20px;
    margin: 3px 20px;

    color: white;
  }

  .stops-box {
    display: flex;
    background-color: #e5d4bc;
    border-radius: 15px;

    padding: 2px 20px;
    margin: 3px 20px;
  }

  .items-box {
    display: flex;
    border: 1px solid black;
    border-radius: 15px;

    padding: 2px 20px;
    margin: 3px 20px;
  }

  p {
    font-weight: 600 !important;
  }
}