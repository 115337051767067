.reservation-overlay {
    .header{
        height: 5rem;
        border-bottom: 2px solid #404040;
        background-color: #ffffff;
    
        h4{
            margin: 0;
            font-weight: 600 !important;
        }
        p{
            margin: 0 7px;
            font-size: 1.2rem;
        }
    }
    .reservation-details{
        background-color: #ffffff;
        height: 10rem;
        border-bottom: 2px solid #404040;

        .icon-outline{
            margin: 5px;
            height: 29px;
            width: 29px;
            border-radius: 50%;
            padding: 1.5px;

            outline-style: solid;
            outline-width: 2px;
            outline-color: $lightblue;
            &.blue {
                background-color: $lightblue;
                color: white;
            }
            &.white {
                color: $lightblue;
            }
        }
        .text-elements{
            margin-left: 5px;
            h6 {
                margin-bottom: 2px;
                font-weight: 600;
                font-size: .9rem;
            }
            p{
                margin: 0;
                font-weight: 400;
                font-size: .8rem;
            }
        }
    }
    .item-details{
        background-color: #ffffff;
        min-height: 15rem;
        max-height: 30rem;
        p{
            margin: 0;
            font-weight: 600;
            font-size: .9rem;
        }
    }
}