.deliveries{

    .filterBox{
        padding: 7px 12px;
        width: 200px;
        
        // justify-content: center;
        flex-direction: row;
        
        background-color: #25c7e3;
        color: white;
        
        border-radius: 10px;
        
    }
    .filterButton {
        padding: 0;
        width: 200px;
        justify-content: flex-start;

        color: white;
        background-color: #25c7e3;
        
        text-transform: none;
        padding: 7px 12px;
        border-radius: 10px;
        
    }
        .filterButton:hover {
            background-color: #117283;
            cursor: pointer;
        }
    
    .reservationBox{
        justify-content: space-between;
        margin: -10px 30px 0 0;
        align-items: flex-end;
    }
    .searchBar{
        width: 250px;
        div{
            border-radius: 125px;   
        }
    }
    hr{
        margin: 10px;
    }
    .progress-bar{
        background-color: #eeeeee;
        color: black;
        p{
            padding: 0;
            margin: 0;

            font-size: .8rem;
        }
    }
    .route-item-listout{
        display: flex;
        align-items: flex-start;
    }

    .pagination{
        justify-content: center;
    }
}