html, body, #root, .App {
  height: auto;
  background-color: #00b4d2;
  margin-bottom: 5rem;
}

.MuiContainer-root.MuiContainer-maxWidthLg {
  @media not print {
    margin-left: 200px;
    max-width: 1400px;
    .MuiContainer-root {
      margin-left: 0;
    }
  }
}

h1, h2, h3, h4, h5 {
  font-weight: 200 !important
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.text-align-left {
  text-align: left
}

.text-align-right {
  text-align: right;
}

.show-print {
  display: none !important;
}

.z-index-0 {
  .MuiPaper-root {
    z-index: 0;
  }
}

@media print {
  .hide-print {
    display: none !important;
  }
  .show-print {
    display: block !important;
  }
  .container {
    max-width: 100%;
  }
  .print-full-width {
    width: 100% !important;
  }
}